import React from 'react';
import {NavBar, NavBottom} from './Navs';
import {Launch} from './About';

function Home(){

    return(
        <>
        <div className="cover">   
        <NavBar/>
        <Launch/>
        <NavBottom/>
        </div>
        </>
    );
    
}
export default Home;
