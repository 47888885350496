import React, { useRef, useEffect, useState } from 'react';
import { useGLTF } from '@react-three/drei';

const GltfViewer = ({ size, position, color, gltfUrl, onClick }) => {
  const { nodes, scene, animations } = useGLTF(gltfUrl);
  const gltfRef = useRef();
  // eslint-disable-next-line
  const [modelLoaded, setModelLoaded] = useState(false);
  // eslint-disable-next-line
  const [modelInfo, setModelInfo] = useState({})
  

  useEffect(() => {
    if (nodes || scene || animations) {
      //console.log('GLTF Model loaded successfully:', { nodes, scene, animations });
      setModelLoaded(true);
      setModelInfo({nodes, scene, animations})
    }
  }, [nodes, scene, animations]);

  if (!scene) {
    return window.alert("Models not loaded"); // or render a placeholder, or loading indicator
  }

  return (
    <group position={position} ref={gltfRef}>
      <primitive object={scene} scale={[size/2,size/2,size/2]}/>
    </group>
  );
};

export default GltfViewer;
