import './App.css';
import React from 'react';
import { Routes, Route} from 'react-router-dom';
import SculptainWorld from './SculptainWorld';
import Home from './Home';

function App(){

    return (
        
        <Routes>
            <Route path='/' element={<Home/>}/>
            <Route path='/world' element={<SculptainWorld/>}/>
            <Route path='/world/:id' element={<SculptainWorld/>}/>
        </Routes>
        
    );
}
export default App;
