import React, { Suspense, useState, useEffect } from 'react';
import ProgressBar   from 'react-bootstrap/ProgressBar';
import {NavHome, NavBottom} from './Navs';
import { Canvas } from '@react-three/fiber';
import  Scene from './Scene';



function LoadingFallback({ loadingPercentage }) {
  return (
    <div className="progress">
            <ProgressBar now={loadingPercentage} label={`Loading Scene: ${loadingPercentage}%`} animated />
    </div>    
       
  );
}
function SculptainWorld() {
  
  const [cameraControl, setCameraControl] = useState('orbit');
  // eslint-disable-next-line
  const [cameraPosition, setCameraPosition] = useState([0, 2, 10]);

  const [loading, setLoading] = useState(false); // Set to false initially
  const [loadingPercentage, setLoadingPercentage] = useState(0);

  const handleCameraPositionChange = (position) => {
    setCameraPosition(position);
  };

  const handleCameraControlSelect = (control) => {
    setCameraControl(control);
  };

  useEffect(() => {
    
    setLoading(true);
    setLoadingPercentage(0);
    const simulateLoading = () => {
      const interval = setInterval(() => {
        
        setLoadingPercentage((prevPercentage) => (prevPercentage < 100 ? prevPercentage + 1 : prevPercentage));
  
        if (loadingPercentage === 100) {
          clearInterval(interval);
          setLoading(false);
        }
      }, 50);
    };
  
    return () => clearInterval(simulateLoading)
  }, [ setLoading, setLoadingPercentage, loadingPercentage]);
  


 

  return (
<>
    
    <NavHome/>
    {//<ToggleControls cameraControl={cameraControl} handleCameraControlSelect={handleCameraControlSelect} />
    }    
     <Suspense fallback={
     <LoadingFallback loadingPercentage={loadingPercentage} />
     //<span>Loading...</span>
     }>
      
       
       
        <Canvas style={{ backgroundColor: '#cce0ff', height: '100vh' }} shadows 
        camera={{ 
          position: [
            477,
            15,
            349
          ]
          }}>
    
          {//<RenderControls cameraControl={cameraControl} handleCameraPositionChange={handleCameraPositionChange} />
          }
          
          <ambientLight intensity={0.5} />
          <spotLight
            intensity={0.8}
            position={[1000, 1000, 1000]}
            angle={0.5}
            penumbra={1}
            castShadow
          />
           <Scene/>
           
          <fog attach="fog" args={['#cce0ff', 25, 500]} />
        </Canvas> 
       
        
      </Suspense>

      <NavBottom/>
  </>    
    
  );
}

export default SculptainWorld;
