import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import {About, Navigation} from './About';
import { SocialIcon } from 'react-social-icons';

export function NavBar () {
    return (
        <>
        <Navbar fixed="top" className="bg-body-tertiary">
            <Container>
                <Navbar.Brand href="#">
                <img
              src="sculptain.png"
              width="40"
              height="40"
              className="d-inline-block align-top"
              alt="Sculptain"
            />{' '}
                    Sculptain
                </Navbar.Brand>
                <Navbar.Toggle/>
                <Navbar.Collapse className="justify-content-end">
                    <Navbar.Text>
                        <About/>
                    </Navbar.Text>
                </Navbar.Collapse>  
            </Container>
        </Navbar>
        </>
    )
}

export function NavBottom () {
    const year = new Date().getFullYear();
    return (
        <>
        <Navbar fixed="bottom" className="bg-body-tertiary">
            <Container>
                <Navbar.Brand>
                <small className="text-secondary">
                    
                &#169; {year} sculptain.xyz 
      
                </small>
                {'  '}
                <SocialIcon target="_blank" url="https://x.com/sculptain" style={{ height: 25, width: 25 }} /> 
                {'  '}
               <a target="_blank" href="https://warpcast.com/wairiuko">
               <img
                  src="farcaster.png"
                  width="25"
                  height="25"
                  className="d-inline-block"
                  alt="farcaster"
                />
                </a>
                </Navbar.Brand>
                <Navbar.Toggle/>
                <Navbar.Collapse className="justify-content-end">
                    <Navbar.Text>
                        <Navigation/>
                           
                    </Navbar.Text>
                </Navbar.Collapse>  
            </Container>
        </Navbar>
        </>
    )
}
export function NavHome () {
    return(
        <Navbar fixed="top" className="bg-transparent">
        <Navbar.Brand href="/">
                <img
              src="sculptain.png"
              width="40"
              height="40"
              className="d-inline-block align-top"
              alt="Sculptain"
            />{' '}
                    
                </Navbar.Brand>
        </Navbar>
    )
}
