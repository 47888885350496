import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import {Link} from 'react-router-dom';

export const About = () => {
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  return(
    <>
    <Button variant="secondary" className="btn-sm" onClick={handleShow}>About</Button>
    <Modal show={show} onHide={handleClose} style={{color: "black"}}>
      <Modal.Header closeButton>
        <Modal.Title>Permanently display your sculptures work in the Sculptain verse </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <div id="static">
              
              
              <p>
              Each 3D artwork is given a permanent display space (think of it as virtual land) in the sculptain metaverse. <br/>
              The first batch of display spaces are 144. Each rock you see represents the space. <br/>
              We are curious to see how this comes through so if you are among the first 144 submissions you get the display space + sculpture airdropped to your wallet.
              </p>
          </div>
      </Modal.Body>
      <Modal.Footer>
        <a href="https://docs.google.com/forms/d/e/1FAIpQLSfTdrkfUipfM3XucJHGpJkJV6znwcJRKVigth33TcYoGfUwdA/viewform" target="_blank" rel="noopener noreferrer" style={{color: "black"}}>Submit Sculpture </a><br/>
      | <a href="https://twitter.com/sculptain" target="_blank" rel="noopener noreferrer" style={{color: "black"}}>Follow us on Twitter</a><br/>
        <Button variant="success" onClick={handleClose}>Close</Button>
        {/*<button className="btn-dark">Continue</button>*/}
      </Modal.Footer>

    </Modal>

    </>
  )
}
export const Navigation = () => {
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  return(
    <>
    <Button variant="secondary" className="btn-sm" onClick={handleShow}>WASD/Arrow keys</Button>
    <Modal show={show} onHide={handleClose} style={{color: "black"}}>
      <Modal.Header closeButton>
        <Modal.Title>Navigation </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <div id="static">
    
              <p>
                Currently the default navigation is PointerLock, WASD/ Arrow keys <br/>
                to move front, left, back and right respectively <br/>
                Click to enter and ESC to exit.
                
              </p>
          </div>
      </Modal.Body>
      <Modal.Footer>
        Enjoy!
      </Modal.Footer>

    </Modal>

    </>
  )
}

export const Launch = () => {

  return(
    <>
    <div className="position-absolute top-50 start-50 translate-middle">
    <Link to="/world" className="btn btn-outline-dark btn-lg" >Launch World</Link>
    </div>
    </>
  )
}
