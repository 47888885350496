import React from 'react';
import {Shape, ExtrudeGeometry} from 'three';


export function Circularparcels({ size, position, color, onClick }) {
    // define the shape of the parcel using three.js's Shape class
    const shape = new Shape();
    // create a circular shape
    shape.absarc(0, 0, size / 2, 0, Math.PI * 2, false);
  
    // extrude the shape to create a 3D geometry
    const extrudeSettings = { depth: size / 100, bevelEnabled: false };
    const geometry = new ExtrudeGeometry(shape, extrudeSettings);
  
    const boundingGeo = new ExtrudeGeometry(shape, { depth: size / 2, bevelEnabled: false });
  
    return (
      <group position={position} >
        <mesh geometry={geometry} rotation={[Math.PI / -2, 0, 0]} onClick={onClick}>
          <meshStandardMaterial color={color} />
        </mesh>
        <mesh visible={true} geometry={boundingGeo} rotation={[Math.PI / -2, 0, 0]}>
          <meshBasicMaterial color="white" opacity={0.3} transparent />
        </mesh>
      </group>
    );
  }
