import React from 'react';
import { Physics } from '@react-three/cannon'
import glbs from './objects.json';
import stands from './objects.json';
import GltfViewer from './GltfViewer';
import Ground from './Ground';
import {Avatar} from './Avatar';
import {Circularparcels} from './Spaces'

function Scene() {
 
return (
  <Physics>
      
   <Ground/>
    <Avatar/>
    {glbs.map((glb) => (
      <GltfViewer 
        key={glb.id}
        size={glb.size}
        position={glb.position}
        gltfUrl={glb.url}
      />
    ))}
    {stands.map((stand) => (
      <Circularparcels
        key={stand.id}
        size={stand.size}
        position={stand.position}
        color={stand.color}
      />
    ))}
    
    
  </Physics>
);
}

export default Scene;


