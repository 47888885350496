import { useRef } from 'react';
import { useFrame, useThree } from '@react-three/fiber';
import { useSphere } from '@react-three/cannon';
import { PointerLockControls } from '@react-three/drei';
import { useEffect } from 'react';
import * as THREE from 'three';

export function Avatar() {
  const { camera, gl } = useThree();
  const controls = useRef();
  const moveForward = useRef(false);
  const moveBackward = useRef(false);
  const moveLeft = useRef(false);
  const moveRight = useRef(false);

  const [ref, api] = useSphere(() => ({
    mass: 1,
    position: [0, 10, 0],
    args: [0.5], // radius
  }));

  useEffect(() => {
    const onKeyDown = (event) => {
      switch (event.code) {
        case 'KeyW':
        case 'ArrowUp':
          moveForward.current = true;
          break;
        case 'KeyS':
        case 'ArrowDown':
          moveBackward.current = true;
          break;
        case 'KeyA':
        case 'ArrowLeft':
          moveLeft.current = true;
          break;
        case 'KeyD':
        case 'ArrowRight':
          moveRight.current = true;
          break;
        default:
          break;
      }
    };
  
    const onKeyUp = (event) => {
      switch (event.code) {
        case 'KeyW':
        case 'ArrowUp':
          moveForward.current = false;
          break;
        case 'KeyS':
        case 'ArrowDown':
          moveBackward.current = false;
          break;
        case 'KeyA':
        case 'ArrowLeft':
          moveLeft.current = false;
          break;
        case 'KeyD':
        case 'ArrowRight':
          moveRight.current = false;
          break;
        default:
          break;
      }
    };
  
    const onPointerLockChange = () => {
        const isLocked = document.pointerLockElement === gl.domElement;
        if (!isLocked) {
          // Reset movement flags when pointer lock is released
          moveForward.current = false;
          moveBackward.current = false;
          moveLeft.current = false;
          moveRight.current = false;
        }
      };
    
      document.addEventListener('keydown', onKeyDown);
      document.addEventListener('keyup', onKeyUp);
      document.addEventListener('pointerlockchange', onPointerLockChange);
    
      return () => {
        document.removeEventListener('keydown', onKeyDown);
        document.removeEventListener('keyup', onKeyUp);
        document.removeEventListener('pointerlockchange', onPointerLockChange);
      };
  }, []);
  

  

  useFrame(() => {
    if (controls.current) {
      const moveSpeed = 1;
      const direction = new THREE.Vector3();

      // Compute movement direction based on keyboard input
      if (moveForward.current) direction.z = -1;
      if (moveBackward.current) direction.z = 1;
      if (moveLeft.current) direction.x = -1;
      if (moveRight.current) direction.x = 1;

      // Apply movement
      controls.current.getObject().translateX(direction.x * moveSpeed);
      controls.current.getObject().translateZ(direction.z * moveSpeed);

      // Update position of the physics body
      ref.current.position.copy(controls.current.getObject().position);
      api.position.copy(ref.current.position);
    }
  });

  return (
    <PointerLockControls ref={controls} />
  );
}
