import * as THREE from 'three';
import { usePlane } from '@react-three/cannon';
import { useTexture } from '@react-three/drei';

export default function Ground() {
  // Load texture
  const texture = useTexture('grass.jpeg');
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(100, 100);

  // Create physics plane
  const [ref] = usePlane(() => ({
    rotation: [-Math.PI / 2, 0, 0],
    args: [2000, 2000], // size of the plane
  }));

  return (
    <mesh ref={ref} receiveShadow>
      <planeGeometry args={[2000, 2000]} />
      <meshStandardMaterial map={texture} />
    </mesh>
  );
}
